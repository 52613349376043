import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import MapComponent from "./components/MapComponent";
import AuthModal from "./components/AuthModal";
import { supabase } from './supabaseConfig';
import "./App.css";

function App() {
  const [searchTerm, setSearchTerm] = useState("");
  const [address, setAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [currentSearchId, setCurrentSearchId] = useState(null);
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setIsAuthenticated(true);
        setUser(session.user);
      }
    };
    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        setIsAuthenticated(true);
        setUser(session.user);
      } else if (event === 'SIGNED_OUT') {
        setIsAuthenticated(false);
        setUser(null);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (currentSearchId) {
      const fetchResults = async () => {
        const { data, error } = await supabase
          .from('search_results')
          .select('*')
          .eq('search_id', currentSearchId)
          .single();

        if (data) {
          setSearchResults(data.result_data);
        } else if (error) {
          console.error('Error fetching results:', error);
        }
      };

      fetchResults();
    }
  }, [currentSearchId]);

  const handleConfirmClick = async () => {
    if (!searchTerm || !address) {
      setErrorMessage("Por favor, preencha todos os campos antes de continuar.");
      return;
    }

    if (!isAuthenticated) {
      setShowAuthModal(true);
      return;
    }

    const { data, error } = await supabase
      .from('searches')
      .insert([
        { user_id: user.id, search_term: searchTerm, address: address }
      ])
      .select();

    if (error) {
      setErrorMessage("Erro ao salvar a pesquisa. Por favor, tente novamente.");
    } else {
      setErrorMessage("");
      setCurrentSearchId(data[0].id);
      // Aqui você pode adicionar lógica adicional após salvar a pesquisa
    }
  };

  const handleAuthSuccess = (userData) => {
    setIsAuthenticated(true);
    setUser(userData);
    setShowAuthModal(false);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setErrorMessage("");
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    setErrorMessage("");
  };

  return (
    <GoogleOAuthProvider 
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      onScriptLoadError={() => console.error('Falha ao carregar o script do Google')}
    >
      <div className="App">
        <h1>Extração de Contatos</h1>
        <input
          id="search-input"
          className="input-field"
          type="text"
          placeholder="Pesquisar por"
          value={searchTerm}
          onChange={handleSearchTermChange}
        />
        <input
          id="address-input"
          className="input-field"
          type="text"
          placeholder="No local:"
          value={address}
          onChange={handleAddressChange}
        />

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <MapComponent address={address} setAddress={setAddress} setErrorMessage={setErrorMessage} />
        <button id="confirm-btn" onClick={handleConfirmClick}>
          OK
        </button>

        {showAuthModal && (
          <AuthModal 
            onAuthSuccess={handleAuthSuccess} 
            onClose={() => setShowAuthModal(false)}
          />
        )}

        {searchResults && (
          <div className="search-results">
            <h2>Resultados da Pesquisa</h2>
            <pre>{JSON.stringify(searchResults, null, 2)}</pre>
          </div>
        )}
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;