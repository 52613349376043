import React, { useEffect, useRef } from "react";

const MapComponent = ({ address, setAddress, setErrorMessage }) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const circleRef = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => initializeMap();
      document.head.appendChild(script);
    };

    loadGoogleMapsScript();
  }, []);

  const initializeMap = () => {
    const initialLocation = { lat: -27.2423392, lng: -50.2188556 };
    const mapOptions = {
      zoom: 7,
      center: initialLocation,
    };

    mapRef.current = new window.google.maps.Map(
      document.getElementById("map"),
      mapOptions
    );

    markerRef.current = new window.google.maps.Marker({
      position: initialLocation,
      map: mapRef.current,
    });

    circleRef.current = new window.google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: mapRef.current,
      center: initialLocation,
      radius: 25000,
      clickable: false,
    });

    const input = document.getElementById("address-input");
    autocompleteRef.current = new window.google.maps.places.Autocomplete(input);
    autocompleteRef.current.bindTo("bounds", mapRef.current);

    autocompleteRef.current.addListener("place_changed", handlePlaceChanged);

    mapRef.current.addListener("click", (event) => {
      placeMarker(event.latLng);
    });
  };

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place.geometry) {
      setErrorMessage(`Não foi possível encontrar o endereço: '${place.name}'`);
      return;
    }

    updateMapAndMarker(place.geometry);
    setAddress(place.formatted_address);
  };

  const updateMapAndMarker = (geometry) => {
    if (geometry.viewport) {
      mapRef.current.fitBounds(geometry.viewport);
    } else {
      mapRef.current.setCenter(geometry.location);
      mapRef.current.setZoom(17);
    }
    markerRef.current.setPosition(geometry.location);
    circleRef.current.setCenter(geometry.location);
  };

  const placeMarker = (location) => {
    markerRef.current.setPosition(location);
    circleRef.current.setCenter(location);
    geocodePosition(location);
  };

  const geocodePosition = (location) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === "OK" && results[0]) {
        setAddress(results[0].formatted_address.replace(/^[A-Z0-9+.-]+,\s*/, ''));
      } else {
        setErrorMessage("Falha ao determinar o endereço.");
      }
    });
  };

  return <div id="map" style={{ width: "100%", height: "500px" }}></div>;
};

export default MapComponent;