import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { supabase } from '../supabaseConfig';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { Eye, EyeOff } from 'react-feather';

const useNotification = () => {
  const [notification, setNotification] = useState(null);

  const showNotification = (message, type = 'info') => {
    setNotification({ message, type });
  };

  const hideNotification = () => {
    setNotification(null);
  };

  return { notification, showNotification, hideNotification };
};

const Notification = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`notification ${type}`}>
      {message}
    </div>
  );
};

const AuthModal = ({ onAuthSuccess, onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { notification, showNotification, hideNotification } = useNotification();
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    digit: false,
    symbol: false
  });
  const [showPassword, setShowPassword] = useState(false);

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone, country) => {
    try {
      return isValidPhoneNumber(phone, country);
    } catch (error) {
      return false;
    }
  };

  const validatePassword = (password) => {
    setPasswordRequirements({
      length: password.length >= 6,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      digit: /[0-9]/.test(password),
      symbol: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?]/.test(password)
    });
  };

  const isPasswordValid = () => {
    return Object.values(passwordRequirements).every(Boolean);
  };

  useEffect(() => {
    validatePassword(password);
  }, [password]);

  const checkEmailExists = async (email) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('email')
      .eq('email', email);

    if (error) {
      console.error('Error checking email:', error);
      return true; // Assume it exists on error to prevent creation
    }

    return data && data.length > 0;
  };

  const validateForm = async () => {
    if (!isLogin) {
      if (password !== confirmPassword) {
        showNotification('As senhas não coincidem', 'error');
        return false;
      }
      if (!validatePhone(phone, 'BR')) {
        showNotification('Por favor, insira um número de telefone válido', 'error');
        return false;
      }
      if (!validateEmail(email)) {
        showNotification('Por favor, insira um email válido', 'error');
        return false;
      }
      if (!isPasswordValid()) {
        showNotification('A senha não atende aos requisitos mínimos', 'error');
        return false;
      }
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        showNotification('Este email já está em uso', 'error');
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!await validateForm()) return;

    if (isLogin) {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });
      if (error) showNotification(error.message, 'error');
      else {
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('subscription_type')
          .eq('id', data.user.id)
          .single();

        if (profileError) {
          showNotification('Erro ao carregar perfil do usuário', 'error');
        } else {
          onAuthSuccess({ ...data.user, subscription_type: profile.subscription_type });
        }
      }
    } else {
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });
      
      if (error) {
        showNotification(error.message, 'error');
      } else {
        const { error: profileError } = await supabase
          .from('profiles')
          .upsert([
            { 
              id: data.user.id, 
              name: name, 
              email: email, 
              phone: phone, 
              subscription_type: 'free' 
            }
          ], { onConflict: 'id' });

        if (profileError) {
          showNotification('Erro ao criar perfil do usuário', 'error');
        } else {
          setIsEmailSent(true);
          showNotification('Cadastro iniciado! Verifique seu email para confirmar.', 'success');
        }
      }
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const { data, error } = await supabase.auth.signInWithIdToken({
        provider: 'google',
        token: response.credential,
      });
      if (error) throw error;
      
      const { data: existingProfile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', data.user.id)
        .single();

      if (profileError && profileError.code === 'PGRST116') {
        const { error: insertError } = await supabase
          .from('profiles')
          .insert([
            { 
              id: data.user.id, 
              name: data.user.user_metadata.full_name,
              email: data.user.email,
              subscription_type: 'free' 
            }
          ]);

        if (insertError) throw insertError;
      } else if (profileError) {
        throw profileError;
      }

      onAuthSuccess({ ...data.user, subscription_type: existingProfile?.subscription_type || 'free' });
    } catch (error) {
      showNotification(error.message, 'error');
    }
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setIsEmailSent(false);
    setPassword('');
    setConfirmPassword('');
  };

  const renderPasswordRequirements = () => {
    const requirements = [
      { key: 'length', label: 'Pelo menos 6 caracteres' },
      { key: 'lowercase', label: 'Uma letra minúscula' },
      { key: 'uppercase', label: 'Uma letra maiúscula' },
      { key: 'digit', label: 'Um número' },
      { key: 'symbol', label: 'Um caractere especial' }
    ];

    return (
      <div className="password-requirements">
        <p>A senha deve conter:</p>
        <ul>
          {requirements.map(({ key, label }) => (
            <li key={key} className={passwordRequirements[key] ? 'requirement-met' : 'requirement-not-met'}>
              {label}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <button className="close-btn" onClick={onClose}>&times;</button>
        <h2>{isLogin ? 'Bem-vindo de volta' : 'Crie sua conta'}</h2>
        <p>{isLogin ? 'Faça login para continuar' : 'Preencha os dados para se cadastrar'}</p>
        {!isEmailSent ? (
          <form onSubmit={handleSubmit}>
            {!isLogin && (
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            )}
            <div className="input-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group password-input-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            {!isLogin && renderPasswordRequirements()}
            {!isLogin && (
              <>
                <div className="input-group">
                  <input
                    type="password"
                    placeholder="Confirme a senha"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group phone-input-container">
                  <PhoneInput
                    country={'br'}
                    value={phone}
                    onChange={setPhone}
                    inputProps={{
                      required: true,
                      autoFocus: true
                    }}
                  />
                </div>
              </>
            )}
            <button type="submit" className="login-btn" disabled={!isLogin && !isPasswordValid()}>
              {isLogin ? 'Entrar' : 'Criar conta'}
            </button>
          </form>
        ) : (
          <div className="email-sent-message">
            <p>Um email de confirmação foi enviado para {email}.</p>
            <p>Por favor, verifique sua caixa de entrada e clique no link para ativar sua conta.</p>
          </div>
        )}
        <p className="toggle-mode" onClick={toggleMode}>
          {isLogin ? 'Não tem uma conta? Cadastre-se' : 'Já tem uma conta? Faça login'}
        </p>
        <div className="divider">
          <span>ou</span>
        </div>
        <div className="google-login-container">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={(error) => {
              console.error('Login Failed:', error);
              showNotification('Falha no login com Google', 'error');
            }}
          />
        </div>
        {notification && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={hideNotification}
          />
        )}
      </div>
    </div>
  );
};

export default AuthModal;